import { useState } from 'react';

export default function useToken() {

  // const getToken = () => {
  //   const tokenString = localStorage.getItem('comsolve_community');
  //   const userToken = JSON.parse(tokenString);
  //   return userToken?.token
  // };

  const [token, setToken] = useState(null);

  const saveToken = (userToken) => {
    localStorage.setItem('comsolve_community', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  const deleteToken = () => {
    localStorage.removeItem("comsolve_community");
    setToken(null);
  };


  return {
    delToken: deleteToken,
    setToken: saveToken,
    token
  }
}
