/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
} from "reactstrap";

import {
  Center,
  CircularProgress,
  Alert,
  AlertIcon,
  AlertTitle,
  useToast,
} from "@chakra-ui/react";

import useForm from "../../hooks/useForm.js";

import React , { useState, useEffect  } from "react";

import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import useToken from "../../hooks/useToken.js";


const Login = () => {

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem("comsolve_community"))?.token || null
  );
  // const { token, setToken, delToken } = useState(null);
  const [user, setUser] = useState(null);
  const { values, handleChange, handleSubmit } = useForm(login);

  const history = useHistory();
  const toast = useToast();

  const saveToken = (userToken) => {
    localStorage.setItem('comsolve_community', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  const deleteToken = () => {
    localStorage.removeItem("comsolve_community");
    setToken(null);
  };

  const logout = () => {

    (async () => {
        const response = await fetch('https://api.comsolve.pt/internal/logout', { method: 'GET', headers: { 'Content-Type': 'application/json' ,
        'Authorization': 'Bearer ' + token}})
            .then((res) => res.json())
            .then((data) => {
              deleteToken();
            })
            .catch((err) => {
                console.log(err.message);
            });
    })();
  }

  function login() {
    setLoading(true);
  
    (async () => {
      try {
        var credentials = {
          "username": values["username"],
          "password": values["password"]
        };


        var credentials2 = {
          "username": "COMSOLVE",
          "password": "COMSOLVE!2022"
        };

        const response = await fetch('https://api.comsolve.pt/internal/login', { method: 'POST', headers: { 'Content-Type': 'application/json'},
          body: JSON.stringify(credentials)});
  
        const data = await response.json();

        const responseExternal = await fetch('https://api.comsolve.pt/external/charging/login', { method: 'POST', headers: { 'Content-Type': 'application/json'},
          body: JSON.stringify(credentials2)});
  
        const dataExternal = await responseExternal.json();

        if (data.status !== "ok") {
          toast({
            render: () => (
              <>
              {data.errors.map((error, index) => (
                <Alert p={3} borderRadius={4} status={data.status} key={index}>
                <AlertIcon />
                <AlertTitle>{error.message || error}  { data.errors[0] === "session_open" && <Button onClick={()=>logout()} size="sm">Fechar sessão</Button>}</AlertTitle>
              </Alert>
              ))}
              </>
            ),
            duration: 6000,
            position: "top",
            isClosable: true,
          });
        } else {
          const comsolve_community = {
            token: data.token,
            tokenExternal: dataExternal.data,
            username: values.username,
          };
  
          setUser(values.username);
          saveToken(comsolve_community);
        }
  
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    })();
  }

  function handleButtonClick() {
    history.push('/admin/mockup');
  }

  // useEffect(() => {

  //   const localStorageData = JSON.parse(localStorage.getItem("comsolve_community"));

  //   if (localStorageData) {

  //     setToken(localStorageData.token);
  //     setUser(localStorageData.username);
  //   }
  // }, [token]);

  if (loading) {
    return (
      <Center h="calc(100vh)">
        <CircularProgress isIndeterminate color="orange.300" />
      </Center>
    );
  }

  if (!token) {
    return (
      <Col lg="6" md="10" style={{height: '100%'}}>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="btn-wrapper text-center">
              <img
                alt="..."
                src={
                  require("../../assets/img/brand/comsolve.png")
                }
              />
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3" id="username">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email/Username"
                    type="text"
                    name="username"
                    autoComplete="new-email"
                    value={values["username"] || ""}
                    onChange={handleChange}/>
                </InputGroup>
              </FormGroup>
              <FormGroup id="password">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    value={values["password"] || ""}
                    onChange={handleChange}/>
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit" disabled={!values["password"] && !values["password"]}>Sign in</Button>
              </div>
              <div className="text-center">
                <a className="text-light" href="register">
                  <small>Create new account</small>
                </a>
              </div>
              <div className="text-center">
                <a className="text-red" href="mockup"  onClick={handleButtonClick}>
                  <small>Mockup</small>
                </a>
                {/* <Switch>
                  <Redirect from="*" to="/admin/mockup" />
                </Switch> */}
                {/* <a className="text-red" href="mockup">
                  <small>Mockup</small>
                </a> */}
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6"></Col>
          <Col className="text-right" xs="6">
            
          </Col>
        </Row>
      </Col>
    );
  }

  if(token){
    return (
      <Switch>
        <Redirect from="*" to="/admin/dashboard" />
      </Switch>
    );
  }


};

export default Login;