/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import moment from "moment";
import React, { useState, useEffect } from "react";
import useFetch from 'use-http';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import ToggleSwitch from "views/examples/ToggleSwitch"

import * as echarts from 'echarts';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Table,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from "reactstrap";

import ReactDatetime from "react-datetime";

import {
  Center,
  CircularProgress
} from "@chakra-ui/react";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import formatData from "../variables/formatData";

const Dashboard = (props) => {

  const dataChart1 = chartExample1["data3"];
  const dataChart2 = chartExample1["data2"];
  const dataChart3 = chartExample1["data4"];

  const [realEstData, setRealEstData] = useState(dataChart1);
  const [prodForecastData, setProdForecastData] = useState(dataChart2);
  const [savingsData, setSavingsData] = useState(dataChart3);
  const [alertsData, setAlertsData] = useState([]);

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingRealEstChart, setLoadingRealEstChart] = useState(false);
  const [loadingProdForChart, setLoadingProdForChart] = useState(false);

  const [ccType, setCCType] = useState(false);

  const [cpes, setCPEs] = useState([]);
  const [clients, setClients] = useState([]);

  const [cpeInfo, setCpeInfo] = useState(null);

  const token = JSON.parse(localStorage.getItem("comsolve_community"))?.token || null;
  const [state, changeState] = useState({startDate: null, endDate: null});
  
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  const { get, post, response, loading, error } = useFetch('https://api.comsolve.pt/internal', config);

  const [timeFrameChartRealvsEst, setTimeFrameRealvsEstChart] = useState("Today");
  const [timeFrameChartSavings, setTimeFrameChartSavings] = useState("This Month");

  const onCCTypeChange = (checked) => {
    setCCType(checked);
  }

  const onClientChange = event => {
    var parFiltrar = event.target.textContent;
    var clientObject = clients.find(obj => obj.ParFiltrar == parFiltrar);
    setCpeInfo(clientObject);
    setCPEs(clientObject.CPE);
  };


  async function setState(date, val){
    var res = {startDate: state.startDate, endDate: state.endDate};
    val = val.toISOString().split('T')[0];
    if (date === 'start') {
      res.startDate = val;
    } else {
      res.endDate = val;
    }
    changeState(res);
  }


  function getDatesForFormat(timeFrame){
    var dateRange = {
      dataInicio: null,
      dataFim: null
    };

    if (timeFrame === "Today") {
      dateRange.dataInicio = moment().format('YYYY-MM-DD');
      dateRange.dataFim = moment().format('YYYY-MM-DD');//moment().add(1, 'days').format('YYYY-MM-DD');
    } 

    if (timeFrame === "This Month"){
      dateRange.dataInicio = moment().subtract(1, 'month').format('YYYY-MM-DD');
      dateRange.dataFim = moment().add(1, 'days').format('YYYY-MM-DD');
    }

    if (timeFrame === "This Year"){
      dateRange.dataInicio = moment().startOf('year').format('YYYY-MM-DD');
      dateRange.dataFim = moment().endOf('year').format('YYYY-MM-DD');
    }

    if (timeFrame === "Custom") {
      dateRange.dataInicio = state.startDate;
      dateRange.dataFim = state.endDate;
    } 
    return dateRange;
  }


  async function updateGraphs(){
    // setTimeFrameRealvsEstChart("Custom");
    getConsumosCurvasCargaPT("Custom");
  }

  useEffect(() => {
    if (cpeInfo) {
      setLoadingPage(true);
      getConsumosCurvasCargaPT();
      getAlertas();
      getSavings();
      setLoadingPage(false);
    }
    
  }, [cpeInfo, cpes]);

  useEffect(() => {
    if (cpeInfo) {
      getEstimatedReal(ccType, "Today");
      getProductionForecast(ccType);
    }
  }, [ccType, cpeInfo]);

  useEffect(() => { 
    setLoadingPage(true);
    getAllClients();
    setLoadingPage(false);
  }, []);

  async function getAllClients(){
    const allClients = await get('/curvas/getAllClients');
    if (response.ok && allClients.status === 'ok'){
      setCpeInfo(allClients.data[0]);
      setClients(allClients.data);
      setCPEs(allClients.data[0].CPE);
    } else {
      // mudar aqui
      console.log(allClients.errors);
    }
    return allClients;
  }

  async function getConsumosCurvasCargaPT(timeFrame = null){
    const dates = timeFrame ? getDatesForFormat(timeFrame) : getDatesForFormat("This Month");

    var body =  {
      dataInicio:dates.dataInicio,
      dataFim: dates.dataFim,
      idTipoServicoCurvaCarga: 1,
      cpe: cpeInfo.CPE[0].cpe,
      nif: cpeInfo.NumContrib
    };
    
    var finalDataChart = {};
    const consumos = await post(`/curvas/getConsumosCurvasCargaPT`, body);
    
    if (response.ok && consumos.status === 'ok'){
      const finalData = consumos.length !== 0 ? formatData(consumos.consumos) : {data: {labels: [], datasets: []}, seriesREN: [], areasSemValor: [], horasSemValor: [], maxYValue: 10};
      // finalDataChart.labels = finalData.labels;
      // finalDataChart.datasets[1].data = finalData.data;
      finalDataChart.data = finalData.data;
      finalDataChart.seriesREN = finalData.seriesREN;
      finalDataChart.areasSemValor = finalData.areasSemValor;
      finalDataChart.horasSemValor = finalData.horasSemValor;
      finalDataChart.maxYValue = finalData.maxYValue;

    } 
    else{
      // mudar aqui
      console.log(consumos.errors);
      finalDataChart = {data: {labels: [], datasets: []}, seriesREN: [], areasSemValor: [], horasSemValor: [], maxYValue: 10};
    }

    const bar_ctx = document.getElementById('canvas');
    var myChart = echarts.init(bar_ctx); 
    var option;
      
    option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            },
            backgroundColor: 'rgba(255, 255, 255, 0.8)'
        },
        legend: {
            show: true,
            top: 10,
            left: 'center',
            data: finalDataChart.seriesREN
        },
        grid: { 
            right: '15%'
        },
        toolbox: {
            feature: {
            dataZoom: {
                yAxisIndex: 'none'
            },
            restore: {},
            saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            triggerEvent: true,
            data : finalDataChart.data.labels,
            name: "Hora",
            nameLocation: "middle",
            nameTextStyle: {
                align: "center",
                verticalAlign: "top",
                lineHeight: 28
            },
        },
        yAxis: {
            type: 'value',
            // boundaryGap: [0, '100%'],
            name: 'A+ (kWh)',
            nameLocation: 'middle',
            nameTextStyle: {
                borderDashOffset: 3,
                align: 'center',
                verticalAlign: 'bottom',
                lineHeight: 46
                },
            max: finalDataChart.maxYValue
        },
        dataZoom: [
            {
                type: 'inside',
                start: 90,
                end: 100
            },
            {
                start: 90,
                end: 100
            }
        ],
        series: [
          {
            name: finalDataChart.seriesREN[2],
            type: 'line',
            data: finalDataChart.data.datasets[2],
            smooth: true,
            lineStyle: {
                normal: { opacity: 0.5, color: 'blue' }
            },
            itemStyle: {
              color: 'blue'
            },
            markArea: {
              itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                      offset: 0,
                      color: 'white'
                  },
                  {
                      offset: 1,
                      color: 'rgba(255,0,0,0.4)'
                  }
                  ])
              }, 
              data: finalDataChart.areasSemValor
            },
            markLine: {
              itemStyle: {
                  color: 'rgba(255, 173, 177, 0.4)'
              },
              data: finalDataChart.horasSemValor
            }
          },
          {
              name: finalDataChart.seriesREN[0],
              type: 'line',
              data: finalDataChart.data.datasets[0],
              smooth: true,
              lineStyle: {
                  normal: { opacity: 0.5, color: '#ffcc00' }
              },
              itemStyle: {
                color: '#ffcc00'
              },
              markArea: {
                  itemStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                          offset: 0,
                          color: 'white'
                      },
                      {
                          offset: 1,
                          color: 'rgba(255,0,0,0.4)'
                      }
                      ])
                  }, 
                  data: finalDataChart.areasSemValor
              },
              markLine: {
                  itemStyle: {
                      color: 'rgba(255, 173, 177, 0.4)'
                  },
                  data: finalDataChart.horasSemValor
              }
          },
          {
              name: finalDataChart.seriesREN[1],
              type: 'line',
              data: finalDataChart.data.datasets[1],
              smooth: true,
              lineStyle: {
                  normal: { opacity: 0.5, color: 'black' }
              },
              itemStyle: {
                color: 'black'
              },
              markArea: {
                  itemStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                          offset: 0,
                          color: 'white'
                      },
                      {
                          offset: 1,
                          color: 'rgba(255,0,0,0.4)'
                      }
                      ])
                  }, 
                  data: finalDataChart.areasSemValor
              },
              markLine: {
                  itemStyle: {
                      color: 'rgba(255, 173, 177, 0.4)'
                  },
                  data: finalDataChart.horasSemValor
              }
          }
        ]
    };
    option && myChart.setOption(option);

    return 1;
  }

  async function getEstimatedReal(ccType, timeFrame = null){
    setLoadingRealEstChart(true);
    timeFrame = timeFrame ? timeFrame : "Today";
    const dates = getDatesForFormat(timeFrame);

    var body_real =  {
      dataInicio:dates.dataInicio,
      dataFim: dates.dataFim,
      idTipoServicoCurvaCarga: 1,
      cpe: cpeInfo.CPE[0].cpe,
      nif: cpeInfo.NumContrib
    };

    var body_estimated =  {
      dataInicio: moment().add(-1, 'days').format('YYYY-MM-DD'),
      dataFim: moment().add(-1, 'days').format('YYYY-MM-DD'),
      idTipoServicoCurvaCarga: 1,
      cpe: cpeInfo.CPE[0].cpe,
      nif: cpeInfo.NumContrib
    };
    
    var finalDataChart = {};
    const consumos = await post(`/curvas/getConsumosCurvasCargaPT`, body_real);
    const consumos_previstos = await post(`/curvas/getConsumosCurvasCargaPT`, body_estimated);
    
    if (response.ok && consumos.status === 'ok' && consumos_previstos.status === 'ok'){
      
      const finalData = consumos.length !== 0 ? formatData(consumos.consumos) : {data: {labels: [], datasets: []}};
      const finalData_estimated = consumos_previstos.length !== 0 ? formatData(consumos_previstos.consumos) : {data: {labels: [], datasets: []}};

      const prodOrCons = ccType ? 1 : 0;

      finalDataChart = {
        labels: finalData.data.labels.map(element => {
          return element.split(' ')[1] + ":00";
        }),
        datasets: [
          {
            label: "Real",
            data: finalData.data.datasets[prodOrCons],
            borderColor: "#ffcc00",
            backgroundColor: "#ffcc00",
            fill: false
          },
          {
            label: "Estimated",
            data: finalData_estimated.data.datasets[prodOrCons],
            borderColor: "black",
            backgroundColor: "black",
            fill: false
          }
        ]
      };

    } 
    else{
      // mudar aqui
      console.log(consumos.errors);
      finalDataChart = {data: {labels: [], datasets: []}, maxYValue: 10};
    }

    setRealEstData(finalDataChart);
    setLoadingRealEstChart(false);
  }

  async function getProductionForecast(ccType){
    setLoadingProdForChart(true);
    const dates = getDatesForFormat("Today");
    
    var body_real =  {
      dataInicio:dates.dataInicio,
      dataFim: dates.dataFim,
      idTipoServicoCurvaCarga: 1,
      cpe: cpeInfo.CPE[0].cpe,
      nif: cpeInfo.NumContrib
    };

    var body_estimated =  {
      dataInicio: moment().add(-1, 'days').format('YYYY-MM-DD'),
      dataFim: moment().add(-1, 'days').format('YYYY-MM-DD'),
      idTipoServicoCurvaCarga: 1,
      cpe: cpeInfo.CPE[0].cpe,
      nif: cpeInfo.NumContrib
    };
    
    var finalDataChart = {};
    const consumos = await post(`/curvas/getConsumosCurvasCargaPT`, body_real);
    const consumos_previstos = await post(`/curvas/getConsumosCurvasCargaPT`, body_estimated);
    
    if (response.ok && consumos.status === 'ok' && consumos_previstos.status === 'ok'){

      const finalData = consumos.length !== 0 ? formatData(consumos.consumos) : {data: {labels: [], datasets: []}};
      const finalData_estimated = consumos_previstos.length !== 0 ? formatData(consumos_previstos.consumos) : {data: {labels: [], datasets: []}};

      const prodOrCons = ccType ? 1 : 0;

      var real_prod = Array.from({ length: Math.max(24 - finalData.data.datasets[prodOrCons].length, 0) }, () => null).concat(finalData.data.datasets[prodOrCons]);
      real_prod = real_prod.map((element) => (element === 0 ? null : element));
      var remaining_hours = 24 - real_prod.filter((element) => element === null).length;
      let newArray = Array.from({ length: 24 }, (_, index) => (index < remaining_hours - 1 ? null : 0));

      newArray = newArray.map((element, index) => (element === 0 ? finalData_estimated.data.datasets[prodOrCons][index] : null));

      finalDataChart = {
        labels: finalData.data.labels.map(element => {
          return element.split(' ')[1] + ":00";
        }),
        datasets: [
          {
            label: "Real",
            data: real_prod,
            borderColor: "#ffcc00",
            backgroundColor: "#ffcc00",
            fill: false
          },
          {
            label: "Estimated",
            data: newArray,
            borderColor: "black",
            backgroundColor: "black",
            fill: false
          }
        ]
      };

    } 
    else{
      // mudar aqui
      console.log(consumos.errors);
      finalDataChart = {data: {labels: [], datasets: []}, maxYValue: 10};
    }
    setProdForecastData(finalDataChart);
    setLoadingProdForChart(false);
  }

  async function getAlertas(){

    var body =  {
      cpe: cpeInfo.CPE[0].cpe,
    };

    const alertas = await post('/curvas/getAlerts', body);
    
    if (response.ok && alertas.status === 'ok'){
      setAlertsData(alertas.data);
    } else {
      // mudar aqui
      console.log(alertas.errors);
    }
    
    return alertas;
  }

  
  async function getSavings(){

    var body =  {
      dataInicio: moment().startOf('month').format('YYYY-MM-DD'),
      dataFim: moment().format('YYYY-MM-DD'),
      cpe: cpeInfo.CPE[0].cpe,
    };

    const savings = await post('/curvas/getSavings', body);
    
    if (response.ok && savings.status === 'ok'){
      var finalDataChart = {
        labels: Object.keys(savings.precos),
        datasets: [
          {
            label: "Savings",
            data: Object.keys(savings.precos).map(date => {
              const { comunidade, mercado } = savings.precos[date];
              return mercado - comunidade;
            }),
            borderColor: "#ffcc00",
            backgroundColor: "#ffcc00",
            fill: false
          }
        ]
      };
      setSavingsData(finalDataChart);
      
    } else {
      // mudar aqui
      console.log(savings.errors);
    }
    
    return savings;
  }

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }


  // useEffect(() => {
    // const result = fetch(`https://api.comsolve.pt/internal/curvas/getAllCPEs`, { method: 'GET' })
    //   .then(response => response.json()) // pass the data as promise to next then block
    //   .then(data => {
    //     const rocketId = data.rocket;
      
    //     return fetch(`${url}/rockets/${rocketId}`); // make a 2nd request and return a promise
    //   })
    //   .then(response => response.json())
    //   .catch(err => {
    //     console.error('Request failed', err)
    // }).finally( () => setCPEs(data));

    // const getData = async () => {
    //   try {

    //     const response = await axios.get('');
    //     setCPEs(response.data);

    //     setError(null);
    //     console.log(data);
    //   } catch (err) {
    //   setError(err.message);
    //   setData(null);
    //   } finally {
    //   setLoading(false);
    //   }
    // };
    // getData();
    // }, [loading]);

  // }, []);

  // useEffect(() => {
  //   window.addEventListener('beforeunload', (event) => {
  //     // Cancel the event as stated by the standard.
  //     event.preventDefault();
  //     // Chrome requires returnValue to be set.
  //     event.returnValue = '';
  //     logout();
  //   });
  // });

  const toggleNavs = (e, graphNumb, timeFrameClicked) => {
    e.preventDefault();
    if (graphNumb === 2) {
      setTimeFrameRealvsEstChart(timeFrameClicked);
    }
    if (graphNumb === 5) {
      setTimeFrameChartSavings(timeFrameClicked);
    }
    
  };

    return !loadingPage && cpeInfo ? (
      <>
          <Header />
          <Container className="mt--7" fluid style={{'backgroundColor': 'white'}}>

            <Row>
              <Col>
                <UncontrolledDropdown nav>
                  <DropdownToggle caret className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block" nav>{cpeInfo.ParFiltrar}</DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow-right">
                  
                    {clients.map((client, index) => (
                        <DropdownItem key={index} onClick={onClientChange}>
                          <NavItem>
                            <NavLink>
                              <span className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block" client={client}>{client.ParFiltrar}</span>
                            </NavLink>
                          </NavItem>
                        </DropdownItem>
                    ))}
                  
                    <DropdownItem >
                      <NavItem>
                        <NavLink>
                          <span className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block">Total</span>
                        </NavLink>
                      </NavItem>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>

              <Col>
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    <NavLink className="nav-link-icon">
                      <h2>{cpeInfo.tipoEntidade}</h2>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              

              <Col>
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    <NavLink className="nav-link-icon">
                      <h2>Cliente Faturação: {cpeInfo.Nome}</h2>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            
            <Row>
              <Col>
                <div><h1>Dashboard</h1></div>
              </Col>
            </Row>

            <Row>
              <Col xs={2}>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Start Date"
                      }}
                      timeFormat={false}
                      dateFormat="DD-MM-YYYY"
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        if (
                          state.startDate &&
                          state.endDate &&
                          state.startDate._d + "" === currentDate._d + ""
                        ) {
                          classes += " start-date";
                        } else if (
                          state.startDate &&
                          state.endDate &&
                          new Date(state.startDate._d + "") <
                            new Date(currentDate._d + "") &&
                          new Date(state.endDate._d + "") >
                            new Date(currentDate._d + "")
                        ) {
                          classes += " middle-date";
                        } else if (
                          state.endDate &&
                          state.endDate._d + "" === currentDate._d + ""
                        ) {
                          classes += " end-date";
                        }
                        return (
                          <td {...props} className={classes}>
                            {currentDate.date()}
                          </td>
                        );
                      }}
                      onChange={e => setState('start', e)}
                      closeOnSelect={true}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs={2}>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "End Date"
                      }}
                      timeFormat={false}
                      dateFormat="DD-MM-YYYY"
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        if (
                          state.startDate &&
                          state.endDate &&
                          state.startDate._d + "" === currentDate._d + ""
                        ) {
                          classes += " start-date";
                        } else if (
                          state.startDate &&
                          state.endDate &&
                          new Date(state.startDate._d + "") <
                            new Date(currentDate._d + "") &&
                          new Date(state.endDate._d + "") >
                            new Date(currentDate._d + "")
                        ) {
                          classes += " middle-date";
                        } else if (
                          state.endDate &&
                          state.endDate._d + "" === currentDate._d + ""
                        ) {
                          classes += " end-date";
                        }
                        return (
                          <td {...props} className={classes}>
                            {currentDate.date()}
                          </td>
                        );
                      }}
                      onChange={e => setState('end', e)}
                      closeOnSelect={true}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs={2}>
                <Button variant="primary" type="submit" onClick={updateGraphs}>
                  <i className="fas fa-search"></i>
                </Button>
              </Col>
            </Row>

            <Row>
              <Col className="mb-5 mb-xl-0" xl="8">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        {/* <h6 className="text-uppercase text-muted ls-1 mb-1">
                          Performance
                        </h6> */}
                        <h2 className="mb-0">Production & Consumption</h2>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart" id="canvas"></div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="4">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        {/* <h6 className="text-uppercase text-light ls-1 mb-1">
                          Overview
                        </h6> */}
                        <h2 className="mb-0">Estimated vs Real</h2>
                      </div>
                      <div className="col-right">
                        <ToggleSwitch id="ccType" checked={ ccType } onChange={ onCCTypeChange } small={false}/>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* Chart */}
                    {loadingRealEstChart ? (
                      <Center h="calc(40vh)">
                        <CircularProgress isIndeterminate color="orange.300" />
                      </Center>
                    ) : (
                      <div className="chart">
                        <Line
                          data={realEstData}
                          options={chartExample1.options}
                          getDatasetAtEvent={(e) => console.log(e)}
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <hr></hr>
            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="4">
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        {/* <h6 className="text-uppercase text-light ls-1 mb-1">
                          Overview
                        </h6> */}
                        <h2 className="mb-0">Forecast</h2>
                      </div>
                      <div className="col-right">
                        <ToggleSwitch id="ccType" checked={ ccType } onChange={ onCCTypeChange } small={false}/>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>

                    {/* Chart */}
                    {loadingProdForChart ? (
                      <Center h="calc(40vh)">
                        <CircularProgress isIndeterminate color="orange.300" />
                      </Center>
                    ) : (
                      <div className="chart">
                        <Line
                          data={prodForecastData}
                          options={chartExample1.options}
                          getDatasetAtEvent={(e) => console.log(e)}
                        />
                      </div>
                    )}
                    
                  </CardBody>
                </Card>
              </Col>
              
              <Col className="mb-5 mb-xl-0" xl="4" >
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        {/* <h6 className="text-uppercase text-light ls-1 mb-1">
                          Overview
                        </h6> */}
                        <h2 className="mb-0">Savings</h2>
                      </div>
                      <div className="col-right">
                        <Nav className="align-items-center d-none d-md-flex" navbar>
                          <UncontrolledDropdown nav>
                            <DropdownToggle caret className="pr-0" nav>{timeFrameChartSavings}</DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow">
                              <DropdownItem >
                                <NavItem>
                                  <NavLink
                                    className={classnames("py-2 px-3")}
                                    href="#pablo"
                                    onClick={(e) => toggleNavs(e, 5, 'This Month')}>
                                    <span className="d-none d-md-block">This Month</span>
                                    <span className="d-md-none">D</span>
                                  </NavLink>
                                </NavItem>
                              </DropdownItem>
                              <DropdownItem>
                                <NavItem>
                                  <NavLink
                                    className={classnames("py-2 px-3")}
                                    href="#pablo"
                                    onClick={(e) => toggleNavs(e, 5, 'This Year')}>
                                    <span className="d-none d-md-block">This Year</span>
                                    <span className="d-md-none">Y</span>
                                  </NavLink>
                                </NavItem>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </Nav>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* Chart */}
                    <div className="chart">
                      <Line
                        data={savingsData}
                        options={{
                          scales: {
                            yAxes: [
                              {
                                scaleLabel: { display: true, labelString: '€' },
                              }
                            ],
                          },
                        }}
                        getDatasetAtEvent={(e) => console.log(e)}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col className="mb-5 mb-xl-0" xl="4">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Alerts</h3>
                      </div>
                      <div className="col text-right">
                        <Button
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          size="sm">See all</Button>
                      </div>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <tbody>
                      
                      {alertsData && (
                        <>
                          {alertsData.map((alert, index) => (
                            <tr key={index}>
                              <th scope="row">
                                <div style={{borderLeft: "2px solid #ffcc00"}}>
                                  <span style={{paddingLeft: '5px'}}>{alert}</span>
                                </div>
                              </th>
                            </tr>
                          ))}
                        </>
                      )}
                      
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
      </>
    ) : (
      <>
        <Header />
        <Container className="mt--7" fluid style={{'backgroundColor': 'white'}}>
          <Center h="calc(100vh)">
            <CircularProgress isIndeterminate color="orange.300" />
          </Center>
        </Container>
      </>
    );

};

export default Dashboard;
