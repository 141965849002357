/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  UncontrolledCollapse,
  NavItem,
  NavLink,
  Badge
} from "reactstrap";

const AdminNavbar = ({logout},props) => {

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main" style={{ 'color': 'white', 'backgroundColor': 'white'}}>
        <Container fluid>

          {/* <Link
            className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block"
            to="/">
            {props.brandText}
          </Link> */}
          <button className="navbar-toggler" id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <Nav className="ml-auto" navbar>

              <NavItem>
                <NavLink className="nav-link-icon" to="/admin/reports" tag={Link}>
                  <i className="ni ni-bell-55 text-dark">
                    <Badge className="badge-danger" pill style={{fontSize: 9, color: 'white', backgroundColor: "#ff0000a8", paddingTop: '3px', paddingBottom: '3px', paddingRight: '3px', paddingLeft: '3px'}}>2</Badge>
                  </i>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink className="nav-link-icon" to="/auth/login" tag={Link} onClick={logout}>
                  {/* single-02 */}
                  <i className="ni ni-user-run text-dark"/>
                </NavLink>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
