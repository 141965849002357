/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Reports from "views/examples/Reports";
import Mockup from "views/Mockup";
import MockupCharger from "views/MockupCharger";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "ni ni-planet text-blue",
    component: Reports,
    layout: "/admin"
  },
  {
    path: "/chargerAndParking",
    name: "Charger & Parking",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin"
  },
  {
    path: "/market",
    name: "Market",
    icon: "ni ni-single-02 text-yellow",
    component: null,
    layout: "/admin"
  },
  {
    path: "/energyMix",
    name: "Energy Mix",
    icon: "ni ni-bullet-list-67 text-red",
    component: null,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/mockup",
    name: "Dashboard",
    icon: "ni ni-circle-08 text-pink",
    component: Mockup,
    layout: "/admin"
  },
  {
    path: "/mockupChargerParking",
    name: "Charger & Parking",
    icon: "ni ni-circle-08 text-pink",
    component: MockupCharger,
    layout: "/admin"
  }
];
export default routes;
