

export default function formatData(dataToFormat){

    var seriesREN = ["Energia entrada", "Energia saída", "Diferença"];
    
    var data = {
        labels: [],
        datasets: [Array.apply(null, Array(dataToFormat.length)).map(function () {}), Array.apply(null, Array(dataToFormat.length)).map(function () {}), Array.apply(null, Array(dataToFormat.length)).map(function () {})]
    };

    var diasComCurvasEmFalta = new Set();
    var today = new Date();

    var horasSem = [];
    
    for (let i = 0; i < dataToFormat.length; i++) {

        data.datasets[0][i] = dataToFormat[i].energiaEntrada != null ? parseFloat(dataToFormat[i].energiaEntrada) : 0;
        data.datasets[1][i] = dataToFormat[i].energiaSaida != null ? parseFloat(dataToFormat[i].energiaSaida) : 0;

        if (dataToFormat[i].energiaEntrada != null || dataToFormat[i].energiaSaida != null){
            var diff = dataToFormat[i].energiaEntrada - dataToFormat[i].energiaSaida;
            data.datasets[2][i] = diff;// > 0 ? diff : 0;
        }
        else {
            horasSem.push(dataToFormat[i].dia + ' ' + dataToFormat[i].hora + 'h');
            if(new Date(dataToFormat[i].dia) < today)
                diasComCurvasEmFalta.add(dataToFormat[i].dia);
        }

        data.labels.push(dataToFormat[i].dia + ' ' + dataToFormat[i].hora + 'h');
    }

    // Calcular max Y
    var maxEntrada = Math.max.apply(this, data.datasets[0]) * 1.1;
    var maxSaida = Math.max.apply(this, data.datasets[1]) * 1.1;
    var maxYValue = Math.max(maxEntrada, maxSaida);

    // Calcular intervalos sem valor
    // var horasSemReduzido = horasSem.reduce((res,date,idx,self) => {
    //     const rangeStart = !idx || new Date(Number(date.slice(0, 4)), Number(date.slice(5, 7)), Number(date.slice(8, 10)), Number(date.slice(11,date.length).replace('h', ''))) - new Date(Number(self[idx-1].slice(0, 4)), Number(self[idx-1].slice(5, 7)), Number(self[idx-1].slice(8, 10)), Number(self[idx-1].slice(11,self[idx-1].length).replace('h', ''))) > 864e5,
    //             rangeEnd = idx == self.length-1 || new Date(Number(self[idx+1].slice(0, 4)), Number(self[idx+1].slice(5, 7)), Number(self[idx+1].slice(8, 10)), Number(self[idx+1].slice(11,self[idx+1].length).replace('h', ''))) - new Date(Number(date.slice(0, 4)), Number(date.slice(5, 7)), Number(date.slice(8, 10)), Number(date.slice(11,date.length).replace('h', ''))) > 864e5
    //     if(rangeStart) res.push({startdate:date,enddate:date}) 
    //     else if(rangeEnd) res[res.length-1]['enddate'] = date 
    //     return res
    // }, []);

    var horasSemReduzido = horasSem.reduce((res, date, idx, self) => {
        const currentDate = new Date(date.replace('h', ':00'));
        const prevDate = new Date(self[idx - 1]?.replace('h', ':00'));
        const isSequentialHour = currentDate - prevDate === 3600000;
      
        if (isSequentialHour) {
          if (!res.length || !res[res.length - 1].enddate) {
            res.push({ startdate: self[idx - 1], enddate: date });
          } else {
            res[res.length - 1].enddate = date;
          }
        } else {
          res.push({ startdate: date, enddate: date });
        }
      
        return res;
      }, []);

    var areasSemValor = [];
    var horasSemValor = [];
    horasSemReduzido.forEach(function(x) {
        if (x.enddate == x.startdate) {
            if (x.enddate > data.labels[0]) {
                horasSemValor.push(
                    {
                        name: 'Sem valor',
                        xAxis: x.startdate, 
                        label: {
                            formatter: '{b}',
                            position: 'end'
                        }
                    }
                );
            }
        }
        else {
            areasSemValor.push([{
                name: 'Sem valor',
                xAxis: x.startdate
            },
            {
                xAxis: x.enddate
            }
            ]);
        }
    });

    
    return {data: data, seriesREN: seriesREN, areasSemValor: areasSemValor, horasSemValor: horasSemValor, maxYValue: maxYValue};
}
