/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDependentScript from "react-dependent-script";

// reactstrap components
import { Card, Container, Row, Col, Table, CardHeader, Button, CardBody, CardFooter, Pagination, PaginationLink, PaginationItem, PopoverBody, UncontrolledPopover, PopoverHeader} from "reactstrap";
import { Line, Pie } from "react-chartjs-2";
import { useState, useRef, useEffect } from "react";
import useFetch from 'use-http';

// core components
import Header from "components/Headers/Header.js";

import MarkerLight from "../assets/img/markers/light.png";
import MarkerParking from "../assets/img/markers/parking.png";

import {
  chartExample1
} from "variables/charts.js";
import moment from "moment";
import { CloseButton } from "@chakra-ui/react";


const MapWrapper = () => {
    
    const data = [
        {
            name: 'Universidade Aveiro',
            latitude: 40.63128852662769,
            longitude: -8.656908278914582
        },
        {
            name: 'Fórum Aveiro',
            latitude: 40.64083011330308, 
            longitude: -8.65183792113293
        },
        {
            name: 'Estacionamento Ílhavo',
            latitude: 40.60079215955708, 
            longitude: -8.667405710925614
        }
    ];

    const [loadingMap, setLoadingMap] = useState(false);
    const [chargingStations, setChargingStations] = useState(data);
    const [marker, setMarker] = useState(null);

  const icons = [
    {title: "Charger", icon: MarkerLight},
    {title: "Parking Lot", icon: MarkerParking}
  ];

  const [map, setMap] = useState(null);
  const [openModal, setOpen] = useState(false);

  const handleOpen = (marker) => {
    setOpen(true);
    setWidth('63%');
    setMarker(marker);
  };

  const handleClose = () => {
    setOpen(false);
    setWidth('100%');
  };

  const [width, setWidth] = useState('100%');

  const mapRef = useRef(null);
  
  useEffect(() => {
    setLoadingMap(true);
    let google = window.google;
    let ChargerMap = mapRef.current;
    let lat = 40.648100349705786;
    let lng = -8.608074874984391;

    const myLatlng = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      mapTypeId: "roadmap",
      zoom: 12,
      center: myLatlng,
      scrollwheel: false,
      zoomControl: true,
      styles: [{
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [{
          "saturation": 36
        }, {
          "color": "#000000"
        }, {
          "lightness": 40
        }]
      }, {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [{
          "visibility": "on"
        }, {
          "color": "#000000"
        }, {
          "lightness": 16
        }]
      }, {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 20
        }]
      }, {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 17
        }, {
          "weight": 1.2
        }]
      }, {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "administrative.country",
        "elementType": "all",
        "stylers": [{
          "visibility": "simplified"
        }]
      }, {
        "featureType": "administrative.country",
        "elementType": "geometry",
        "stylers": [{
          "visibility": "simplified"
        }]
      }, {
        "featureType": "administrative.country",
        "elementType": "labels.text",
        "stylers": [{
          "visibility": "simplified"
        }]
      }, {
        "featureType": "administrative.province",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "administrative.locality",
        "elementType": "all",
        "stylers": [{
          "visibility": "simplified"
        }, {
          "saturation": "-100"
        }, {
          "lightness": "30"
        }]
      }, {
        "featureType": "administrative.neighborhood",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "administrative.land_parcel",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [{
          "visibility": "simplified"
        }, {
          "gamma": "0.00"
        }, {
          "lightness": "74"
        }]
      }, {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 20
        }]
      }, {
        "featureType": "landscape.man_made",
        "elementType": "all",
        "stylers": [{
          "lightness": "3"
        }]
      }, {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 21
        }]
      }, {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{
          "visibility": "simplified"
        }]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 17
        }]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 29
        }, {
          "weight": 0.2
        }]
      }, {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 18
        }]
      }, {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 16
        }]
      }, {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 19
        }]
      }, {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 17
        }]
      }]
    };

    ChargerMap = new google.maps.Map(ChargerMap, mapOptions);

    // Create markers.
    for (let i = 0; i < chargingStations.length; i++) {
        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(data[i].latitude, data[i].longitude),
            map: ChargerMap,
            icon: data[i].name == 'Estacionamento Ílhavo' ? MarkerParking : MarkerLight,
            animation: google.maps.Animation.DROP,
            title: data[i].name
        });

    // const contentString =
    // '<div class="info-window-content"><h2>Light Bootstrap Dashboard PRO React</h2>' +
    // "<p>A premium Admin for React-Bootstrap, Bootstrap, React, and React Hooks.</p></div>";

    // const infowindow = new google.maps.InfoWindow({
    //   content: contentString
    // });

        google.maps.event.addListener(marker, "click", function(){ handleOpen(data[i]);});
    }
    //     // function () { 
    //       // infowindow.open(ChargerMap, marker);
    //       // handleOpen
    //       // }
    setLoadingMap(false);
    
  }, []);

  return (
    <>
      {marker && 
        <UncontrolledPopover marker={marker} placement="left" target="map-canvas" style={{ height: '800px', width: '200px'}} isOpen={openModal}>
          <PopoverHeader>
            Charging station
            <CloseButton variant="white" placement="right" onClick={(e) => handleClose()}></CloseButton>
          </PopoverHeader>
          <PopoverBody>
            <h3 style={{fontWeight: 'bold'}}>Name</h3>
            <p>{marker.name}</p>
            <h3 style={{fontWeight: 'bold'}}>Model</h3>
            <p>{marker.model}</p>
            {/* <h3 style={{fontWeight: 'bold'}}>Energy Mix</h3>
            <div className="chart">
              <Pie data={data}/>
            </div> */}
          </PopoverBody>
        </UncontrolledPopover>
      }
      
      <div
        style={{ height: `820px`, width: width, marginLeft: 'auto'}}
        className="map-canvas"
        id="map-canvas"
        ref={mapRef}>
      </div>
      <div
        style={{ height: `100px`, width: width, marginLeft: 'auto', display: 'flex', justifyContent: 'center', objectFit: 'contain', paddingTop: '10px'}}
        className="legend"
        id="legend">
          {icons.map(icon => {
              return (
                <div style={{ paddingRight: '10px', overflow: 'auto'}} key={icon.title}>
                  <img src={icon.icon}/>
                  <b>{icon.title}</b>
                </div>
              );
          })}
      </div>
    </>
  );
};

const MockupCharger = () => {

  const dataChart3 = chartExample1["data5"];
  const [chart3Data, setChart3Data] = useState(dataChart3);
  var options = {legend: { display: false }, 
    tooltips: { enabled: false }, 
    scales: { yAxes: [{ scaleLabel: { display: true, labelString: '€/kWh' }, gridLines: { drawOnChartArea:false}}], xAxes: [{ gridLines: { drawOnChartArea:false }}]}};


  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid style={{'backgroundColor': 'white'}}>
        <Row>
          <Col>
            <div><h1>Charger & Parking</h1></div>
          </Col>
        </Row>
        <Row>
        <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Schedule Charging</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      className="bg-yellow text-white"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm">Schedule</Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead">
                  <tr>
                    <th scope="col" className="mb-0">Date</th>
                    <th scope="col" className="mb-0">Time</th>
                    <th scope="col" className="mb-0">Duration</th>
                    <th scope="col" className="mb-0">Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>26-03-2022</td>
                    <td>21:00</td>
                    <td>40 min</td>
                    <td>Repsol Estação de Carregamento</td>
                  </tr>
                  <tr>
                    <td>26-03-2022</td>
                    <td>09:00</td>
                    <td>30 min</td>
                    <td>Repsol Estação de Carregamento</td>
                  </tr>
                  <tr>
                    <td>15-03-2022</td>
                    <td>20:00</td>
                    <td>90 min</td>
                    <td>PowerDot Charging Station</td>
                  </tr>
                  <tr>
                    <td>04-03-2022</td>
                    <td>19:00</td>
                    <td>20 min</td>
                    <td>EDP Commercial Charging Station</td>
                  </tr>
                  <tr>
                    <td>04-03-2022</td>
                    <td>10:00</td>
                    <td>60 min</td>
                    <td>Repsol Estação de Carregamento</td>
                  </tr>
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1">
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}>1</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}>2<span className="sr-only">(current)</span></PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}>
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    {/* <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6> */}
                    <h2 className="mb-0">Energy Price Variation</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Line
                    data={chart3Data}
                    options={options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col">
            <Card className="border-0">
            <ReactDependentScript
              scripts={["https://maps.googleapis.com/maps/api/js?key=AIzaSyCA3Sr4pivAGIkbJdRgowfCHcjd-lnZY1U&libraries=places"]}>
              <MapWrapper/>
            </ReactDependentScript>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MockupCharger;
