/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import useToken from "../hooks/useToken.js";
import routes from "routes.js";
import Mockup from "views/Mockup";
import MockupCharger from "views/MockupCharger";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  const [token, setToken] = useState(JSON.parse(localStorage.getItem("comsolve_community"))?.token || null);
  
  let routes_admin = null;

  if (location.pathname == "/admin/mockup" || location.pathname == "/admin/mockupChargerParking") {
    // routes_admin = routes.filter(route => route.path == "/mockup");
    routes_admin = [
      {
        path: "/mockup",
        name: "Dashboard",
        icon: "ni ni-circle-08 text-pink",
        component: Mockup,
        layout: "/admin"
      },
      {
        path: "/mockupChargerParking",
        name: "Charger & Parking",
        icon: "ni ni-circle-08 text-pink",
        component: MockupCharger,
        layout: "/admin"
      },
    ];
  } else {
    routes_admin = routes.filter(route => route.layout === "/admin" && !route.path.includes("mockup"));

  }

  const deleteToken = () => {
    localStorage.removeItem("comsolve_community");
    setToken(null);
  };


  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const logout = () => {

    (async () => {
        const response = await fetch('https://api.comsolve.pt/internal/logout', { method: 'GET', headers: { 'Content-Type': 'application/json' ,
        'Authorization': 'Bearer ' + token}})
            .then((res) => res.json())
            .then((data) => {
              deleteToken();
              window.location.href = '/auth/login';
            })
            .catch((err) => {
                console.log(err.message);
            });
    })();
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={routes_admin}
        logo={{
          innerLink: "/admin/dashboard",
          imgSrc: require("../assets/img/brand/comsolve.png"),
          imgAlt: "..."
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
          logout={logout}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/dashboard" />
        </Switch>
        <Container fluid style={{'backgroundColor': 'white'}}>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
