/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

import {
  Center,
  CircularProgress,
  Alert,
  AlertIcon,
  AlertTitle,
  useToast,
} from "@chakra-ui/react";

import React , { useState, useEffect  } from "react";
import useForm from "../../hooks/useForm.js";
import useFetch from 'use-http';
import { Switch, Redirect } from "react-router-dom";

const Register = () => {

  const [loading, setLoading] = useState(false);
  const { values, handleChange, handleSubmit } = useForm(register);
  const [createdAcc, setCreatedAcc] = useState(false);
  const toast = useToast();

  const config = {
    headers: { 
              'Content-Type': 'application/json' 
            }
  };

  const { get, post, response, error } = useFetch('https://api.comsolve.pt/external', config);


  function register() {
    setLoading(true);
  
    (async () => {
      try {

        var body = {
          "auth_passwd": encodeURIComponent(values["password"]),
          "email": values["email"],
          "login_name": values["login_name"],
          "full_name": values["full_name"],
        };

        const response = await post('/register', JSON.stringify(body));
  
        const data = await response.json();

        console.log(data);

        if (data.status !== "ok") {
          toast({
            render: () => (
              <>
              {data.errors.map((error, index) => (
                <Alert p={3} borderRadius={4} status={data.status} key={index}>
                <AlertIcon />
                <AlertTitle>{error.message || error} </AlertTitle>
              </Alert>
              ))}
              </>
            ),
            duration: 6000,
            position: "top",
            isClosable: true,
          });
        } else {
          setCreatedAcc(true);
        }
  
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    })();
  }

  if (loading) {
    return (
      <Center h="calc(100vh)">
        <CircularProgress isIndeterminate color="orange.300" />
      </Center>
    );
  }

  if (createdAcc) {
    return (
      <Switch>
        <Redirect from="*" to="/auth/login" />
      </Switch>
    );
  } else {
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="btn-wrapper text-center">
                <img
                  alt="..."
                  src={
                    require("../../assets/img/brand/comsolve.png")
                  }
                />
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form" onSubmit={handleSubmit}>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      placeholder="Full Name" 
                      type="text" 
                      name="full_name"
                      autoComplete=""
                      value={values["full_name"] || ""}
                      onChange={handleChange} 
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      placeholder="Login Name" 
                      type="text"
                      name="login_name"
                      autoComplete=""
                      value={values["login_name"] || ""}
                      onChange={handleChange} 
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      name="email"
                      autoComplete=""
                      value={values["email"] || ""}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      name="auth_passwd"
                      autoComplete=""
                      value={values["auth_passwd"] || ""}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                {/* <div className="text-muted font-italic">
                  <small>
                    password strength:{" "}
                    <span className="text-success font-weight-700">strong</span>
                  </small>
                </div> */}
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="submit" disabled={!values["auth_passwd"] || !values["email"] || !values["login_name"] || !values["full_name"]}>
                    Create account
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }

  
};

export default Register;
