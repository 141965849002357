/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// react component that copies the given text inside your clipboard
// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row
} from "reactstrap";
import { useEffect, useState } from "react";
import useFetch from 'use-http';
import {
  Center,
  CircularProgress
} from "@chakra-ui/react";
import Header from "components/Headers/Header.js";

const Reports = () => {

  const [loadingPage, setLoadingPage] = useState(false);
  const [alertsData, setAlertsData] = useState(null);
  const [reportsData, setReportsData] = useState(null);

  const cpe = "PT0002000120112876EW";

  const token = JSON.parse(localStorage.getItem("comsolve_community"))?.token || null;

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const { get, post, response, loading, error } = useFetch('https://api.comsolve.pt/internal', config);
  
  async function getReports(){

    var body =  {
      cpe: cpe,
      reports: 1
    };

    const reports = await post('/curvas/getAlerts', body);
    
    if (response.ok && reports.status === 'ok'){
      const alertas = Object.entries(reports.data).filter(([key]) => key !== "reports").map(([_, value]) => value);
      setAlertsData(alertas);
      setReportsData(reports.data.reports);
    } else {
      // mudar aqui
      console.log(reports.errors);
    }
    
    return reports;
  }


  useEffect(() => {
    setLoadingPage(true);
    getReports();
    setLoadingPage(false);
  }, []);


  return !loadingPage && reportsData ? (
    
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid style={{'backgroundColor': 'white'}}>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Reports</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Alert ID</th>
                    <th scope="col">Date Created</th>
                    <th scope="col">Date Solved</th>
                    <th scope="col">Description</th>
                    <th scope="col">CPE</th>
                    {/* <th scope="col" /> */}
                  </tr>
                </thead>
                <tbody>

                  {alertsData && (
                    <>
                      {alertsData.map((alert, index) => (
                        <tr key={index}>
                          <th scope="row">-</th>
                          <td>-</td>
                          <td>-</td>
                          <td>{alert}</td>
                          <td>{cpe}</td>
                        </tr>
                      ))}
                    </>
                  )}
                  
                  {reportsData && (
                    <>
                      {reportsData.map((report, index) => (
                        <tr key={index}>
                          <th scope="row">{report.idAlerta}</th>
                          <td>{report.dataAlerta}</td>
                          <td>{report.dataResolucao}</td>
                          <td>{report.descricao}</td>
                          <td>{cpe}</td>
                        </tr>
                      ))}
                    </>
                  )}

                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>

  ) : (
    
    <>
      <Header />
      <Container className="mt--7" fluid style={{'backgroundColor': 'white'}}>
        <Center h="calc(100vh)">
          <CircularProgress isIndeterminate color="orange.300" />
        </Center>
      </Container>
    </>
  
  );
};

export default Reports;
