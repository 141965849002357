/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDependentScript from "react-dependent-script";

// reactstrap components
import { Card, Container, Row, Col, Table, CardHeader, Button, CardBody, CardFooter, Pagination, PaginationLink, 
  PaginationItem, PopoverBody, UncontrolledPopover, PopoverHeader, Modal, ModalFooter, ModalBody, FormGroup, Form,
  InputGroup, InputGroupAddon, InputGroupText, Input} from "reactstrap";
import ReactDatetime from "react-datetime";

import { Line, Pie } from "react-chartjs-2";
import { useState, useRef, useEffect } from "react";
import useFetch, { Provider } from 'use-http';

// core components
import Header from "components/Headers/Header.js";

import MarkerLight from "../../assets/img/markers/light.png";
import MarkerLightUnavailable from "../../assets/img/markers/light_unavailable.png";
import MarkerLightAvailable from "../../assets/img/markers/light_available.png";
import MarkerParking from "../../assets/img/markers/parking.png";

import {
  chartExample1
} from "variables/charts.js";
import moment from "moment";
import { CloseButton, useToast, ChakraProvider } from "@chakra-ui/react";


function MapWrapper({ setStations}) {

  const [loadingMap, setLoadingMap] = useState(false);
  const { get, post, response, loading, error } = useFetch('https://api.comsolve.pt');
  
  const [chargingStations, setChargingStations] = useState([]);

  const [marker, setMarker] = useState(null);

  const icons = [
    {title: "Charger", icon: MarkerLight},
    {title: "Parking Lot", icon: MarkerParking}
  ];

  const [map, setMap] = useState(null);
  const [openModal, setOpen] = useState(false);

  const handleOpen = (marker) => {
    
    getStationInfo(marker.id).then(data => {
      setOpen(true);
      setWidth('63%');
      let connector = data.data.connectors.find(obj => obj.status === 'Charging' || obj.status === 'Finish');
      let status = connector ? connector.status + ' (' + connector.chargestandard + ')' : 'Available';
      marker.status = status;
      marker.connectors = data.data.connectors;
      setMarker(marker);
    });
    
  };

  const handleClose = () => {
    setOpen(false);
    setWidth('100%');
    setMarker(null);
  };

  const [width, setWidth] = useState('100%');

  const mapRef = useRef(null);

  async function getAllChargingStations() {
    const allStations = await get('external/charging/info/stations');
    
    if (response.ok && allStations.status === 'ok'){
      setChargingStations(allStations.data.stations);
      setStations(allStations.data.stations);
    } else {
      // mudar aqui
      console.log(allStations.errors);
    }

    return allStations;
  }

  async function getStationInfo(stationId) {
    const stationInfo = await get('external/charging/info/stations/' + stationId);
    
    if (!response.ok || stationInfo.status !== 'ok'){
      console.log(stationInfo.errors);
    }

    return stationInfo;
  }
  
  useEffect(() => {
    setLoadingMap(true);
    let google = window.google;
    let ChargerMap = mapRef.current;
    let lat = 40.648100349705786;
    let lng = -8.608074874984391;

    const myLatlng = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      mapTypeId: "roadmap",
      zoom: 12,
      center: myLatlng,
      scrollwheel: false,
      zoomControl: true,
      styles: [{
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [{
          "saturation": 36
        }, {
          "color": "#000000"
        }, {
          "lightness": 40
        }]
      }, {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [{
          "visibility": "on"
        }, {
          "color": "#000000"
        }, {
          "lightness": 16
        }]
      }, {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 20
        }]
      }, {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 17
        }, {
          "weight": 1.2
        }]
      }, {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "administrative.country",
        "elementType": "all",
        "stylers": [{
          "visibility": "simplified"
        }]
      }, {
        "featureType": "administrative.country",
        "elementType": "geometry",
        "stylers": [{
          "visibility": "simplified"
        }]
      }, {
        "featureType": "administrative.country",
        "elementType": "labels.text",
        "stylers": [{
          "visibility": "simplified"
        }]
      }, {
        "featureType": "administrative.province",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "administrative.locality",
        "elementType": "all",
        "stylers": [{
          "visibility": "simplified"
        }, {
          "saturation": "-100"
        }, {
          "lightness": "30"
        }]
      }, {
        "featureType": "administrative.neighborhood",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "administrative.land_parcel",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [{
          "visibility": "simplified"
        }, {
          "gamma": "0.00"
        }, {
          "lightness": "74"
        }]
      }, {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 20
        }]
      }, {
        "featureType": "landscape.man_made",
        "elementType": "all",
        "stylers": [{
          "lightness": "3"
        }]
      }, {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 21
        }]
      }, {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{
          "visibility": "simplified"
        }]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 17
        }]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 29
        }, {
          "weight": 0.2
        }]
      }, {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 18
        }]
      }, {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 16
        }]
      }, {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 19
        }]
      }, {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [{
          "color": "#000000"
        }, {
          "lightness": 17
        }]
      }]
    };

    ChargerMap = new google.maps.Map(ChargerMap, mapOptions);

    getAllChargingStations().then(data => { 
      data = data.data.stations;
      // Create markers.
      for (let i = 0; i < data.length; i++) {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(data[i].latitude, data[i].longitude),
          map: ChargerMap,
          icon: data[i].isonline ? MarkerLightAvailable : MarkerLightUnavailable,
          animation: google.maps.Animation.DROP,
          title: data[i].name
        });

        // const contentString =
        // '<div class="info-window-content"><h2>Light Bootstrap Dashboard PRO React</h2>' +
        // "<p>A premium Admin for React-Bootstrap, Bootstrap, React, and React Hooks.</p></div>";

        // const infowindow = new google.maps.InfoWindow({
        //   content: contentString
        // });

        // function () { 
          // infowindow.open(ChargerMap, marker);
          // handleOpen
        // }

        google.maps.event.addListener(marker, "click", function(){ handleOpen(data[i]);});
      }
    });
    setLoadingMap(false);
    
  }, []);

  return (
    <>
      {marker && 
        <UncontrolledPopover marker={marker} placement="left" target="map-canvas" style={{ height: '800px', width: '200px'}} isOpen={openModal}>
          <PopoverHeader>
            Charging station
            <CloseButton variant="white" placement="right" onClick={(e) => handleClose()}></CloseButton>
          </PopoverHeader>
          <PopoverBody>
            <h3 style={{fontWeight: 'bold'}}>Name</h3>
            <p>{marker.name}</p>
            <h3 style={{fontWeight: 'bold'}}>Model</h3>
            <p>{marker.model}</p>
            <h3 style={{fontWeight: 'bold'}}>Status</h3>
            <p>{marker.status}</p>
            <h3 style={{fontWeight: 'bold'}}>Max Power</h3>
            <p>{marker.connectors[0].maxpower/1000} kW</p>
          </PopoverBody>
        </UncontrolledPopover>
      }
      
      <div
        style={{ height: `820px`, width: width, marginLeft: 'auto'}}
        className="map-canvas"
        id="map-canvas"
        ref={mapRef}>
      </div>
      <div
        style={{ height: `100px`, width: width, marginLeft: 'auto', display: 'flex', justifyContent: 'center', objectFit: 'contain', paddingTop: '10px'}}
        className="legend"
        id="legend">
          {icons.map(icon => {
              return (
                <div style={{ paddingRight: '10px', overflow: 'auto'}} key={icon.title}>
                  <img src={icon.icon}/>
                  <b>{icon.title}</b>
                </div>
              );
          })}
      </div>
    </>
  );
};

function formatPrecos(precos){
  var today = moment().format('DD');
  var tomorrow = moment().add(1, 'days').format('DD');
  var finalData = {labels: [], data: []};
  for (let i = 0; i < precos.x.length; i++) {
    var day = precos.x[i].split('-')[2].split(' ')[0];
    if (day == today || day == tomorrow) {
      finalData.labels.push(`${precos.x[i].split('-')[2].split(' ')[0]}/${precos.x[i].split('-')[1]} ${precos.x[i].split(' ')[1]}`);
      finalData.data.push(precos.y[i]);
    }
  }
  return finalData;
}

function Wrapper(){

  const dataDefaultPrices = chartExample1["data5"];

  const [pricevariation, setPriceVariation] = useState({});
  const [stations, setStations] = useState(null);
  const [reservations, setReservations] = useState([]);

  const [stationToReserve, setStationToReserve] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);

  var today = moment().format('YYYY-MM-DDTHH:mm:ss');
  const [dateSchedule, changeDate] = useState(today);

  const { get, post, response, loading, error } = useFetch('https://api.comsolve.pt');

  const toast = useToast();

  var optionsGraph = {legend: { display: false }, 
    tooltips: { enabled: false }, 
    scales: { yAxes: [{ scaleLabel: { display: true, labelString: '€/MWh' }, gridLines: { drawOnChartArea:false}}], xAxes: [{ gridLines: { drawOnChartArea:false }}]
  }};

  const reserve = () => {
    getStationInfo(stationToReserve).then(data => {

      var connector_id = data.data.connectors[0].connectorid;

      getCardIdToken().then(data => {

        var body =  {
          card_id_token: data[0].cardname,
          station_id: stationToReserve,
          connector_id: connector_id,
          expiry_date_time: dateSchedule
        };
  
        makeReservation(body);
      });

    });
  };

  async function getCardIdToken() {
    const sessionsInfo = await get('external/charging/info/cards');
    
    if (!response.ok || sessionsInfo.status !== 'ok'){
      console.log(sessionsInfo.errors);
    }

    return sessionsInfo.data.cards;
  }

  async function getStationInfo(stationId) {
    const stationInfo = await get('external/charging/info/stations/' + stationId);
    
    if (!response.ok || stationInfo.status !== 'ok'){
      console.log(stationInfo.errors);
    }

    return stationInfo;
  }


  async function getReservations(){

    const reservas = await get('external/charging/info/stations/reservations_all');
    
    if (response.ok && reservas.status == 'ok'){
        setReservations(reservas.data.reservations);
    } else{
      // mudar aqui
      console.log(reservas.errors);
    }

  }

  async function getPrecos(){
    var body =  {
      dataPesquisa: moment().format('YYYY-MM')
    };
    
    var finalDataChart = dataDefaultPrices();
    const precos = await post(`internal/market/getPricesOMIE`, body);
    
    if (response.ok && precos.status == 'ok'){
      if (precos.data.y.length != 0) {
        const finalData = formatPrecos(precos.data);
        finalDataChart.labels = finalData.labels;
        finalDataChart.datasets[0].data = finalData.data;
      }
    } else{
      // mudar aqui
      console.log(precos.errors);
    }

    setPriceVariation(finalDataChart);
  }

  async function makeReservation(body){
    
    const reserva = await post('external/charging/remote/reserve', body);
    
    if (response.ok && reserva.status == 'ok'){
      toast({
        title: reserva.status.toUpperCase(),
        description: "Reserva feita com sucesso",
        status: "success",
        duration: 5000, 
        isClosable: true,
        position: "top",
      });
      setModalOpen(false);
    } else{
      // mudar aqui
      toast({
        title: reserva.status.toUpperCase(),
        description: "Ocorreu um erro ao tentar efetuar a reserva. " + error.message,
        status: "error",
        duration: 5000, 
        isClosable: true,
        position: "top",
      });
      console.log(reserva.errors);
    }
  }

  useEffect(() => { 
    getPrecos();
    getReservations();
  }, []);


  useEffect(() => {
    if (stations) {
      setStationToReserve(stations[0].id);
    }
  }, [stations]);

  return (
    <>
    <ChakraProvider>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid style={{'backgroundColor': 'white'}}>
        <Row>
          <Col>
            <div><h1>Charger & Parking</h1></div>
          </Col>
        </Row>
        <Row>
        <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Schedule Charging</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      className="bg-yellow text-white"
                      href="#/schedule"
                      onClick={() => setModalOpen(!modalOpen)}
                      size="sm">Schedule</Button>
                    <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
                      <div className=" modal-header">
                        <h5 className=" modal-title" id="exampleModalLabel">
                          Schedule Charging
                        </h5>
                        <button
                          aria-label="Close"
                          className=" close"
                          type="button"
                          onClick={() => setModalOpen(!modalOpen)}>
                          <span aria-hidden={true}>×</span>
                        </button>
                      </div>
                      <ModalBody>
                      <Row>
                        <Col xs={4}><label style={{marginTop: '10px'}}>Date and Time:</label></Col>
                        <Col xs={7}>
                          <Form>
                            <FormGroup>
                              <Input
                                value={dateSchedule}
                                onChange={changeDate}
                                id="datetime-local-input"
                                type="datetime-local"
                              ></Input>
                            </FormGroup>
                          </Form>
                        </Col>
                        <Col xs={4}><label style={{marginTop: '10px'}}>Duration (minutes):</label></Col>
                        <Col xs={7}>
                          <Form>
                            <FormGroup>
                              <Input
                                id="hours-input"
                                type="number"
                                min={1}
                                defaultValue={30}
                                step={5}
                              ></Input>
                            </FormGroup>
                          </Form>
                        </Col>
                        <Col xs={4}><label style={{marginTop: '10px'}}>Station:</label></Col>
                        <Col xs={7}>
                          <Form>
                            <FormGroup>
                              <Input type="select" onChange={(e) => setStationToReserve(e.target.value)}>
                              {stations && stations.map((station, index) => (
                                  <option key={index} value={station.id}>
                                    {station.name}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" type="button" onClick={() => setModalOpen(!modalOpen)}>
                          Cancel
                        </Button>
                        <Button color="primary" type="button" onClick={() => reserve()}>
                          Submit
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead">
                  <tr>
                    <th scope="col" className="mb-0">Date</th>
                    <th scope="col" className="mb-0">Time</th>
                    <th scope="col" className="mb-0">Duration</th>
                    <th scope="col" className="mb-0">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {reservations && (
                      <>
                        {reservations.map((reservation, index) => (
                          <tr key={index}>
                            <td>{reservation.idAlerta}</td>
                            <td>{reservation.dataAlerta}</td>
                            <td>{reservation.dataResolucao}</td>
                            <td>{reservation.descricao}</td>
                          </tr>
                        ))}
                      </>
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0">
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1">
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}>1</PaginationLink>
                    </PaginationItem>
                    {/* <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}>2<span className="sr-only">(current)</span></PaginationLink>
                    </PaginationItem> */}
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}>
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    {/* <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6> */}
                    <h2 className="mb-0">Energy Price Variation on the Market</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Line
                    data={pricevariation}
                    options={optionsGraph}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col">
            <Card className="border-0">
            <ReactDependentScript
              scripts={["https://maps.googleapis.com/maps/api/js?key=AIzaSyCA3Sr4pivAGIkbJdRgowfCHcjd-lnZY1U&libraries=places"]}>
              <MapWrapper setStations={setStations}></MapWrapper>
            </ReactDependentScript>
            </Card>
          </div>
        </Row>
      </Container>
    </ChakraProvider>
    </>
  );
}

const Maps = () => {

  const token = JSON.parse(localStorage.getItem("comsolve_community"))?.token || null;
  const tokenExternal = JSON.parse(localStorage.getItem("comsolve_community"))?.tokenExternal || null;

  const globalOptions = {
    interceptors: {
      request: async ({ options, url, path, route }) => {
        if (route.includes('external')) {
          options.headers.Authorization = `${tokenExternal}`;
        } else{
          options.headers.Authorization = `Bearer ${token}`;
        }
        return options;
      },
    }
  };

  return (
    <>
      <Provider options={globalOptions}>
        <Wrapper/>
      </Provider>
    </>
  );
};

export default Maps;
