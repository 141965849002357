/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import moment from "moment";
import React, { useState, useEffect } from "react";
import useFetch from 'use-http';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

import * as echarts from 'echarts';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Table,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from "reactstrap";

import ReactDatetime from "react-datetime";

import {
  Center,
  CircularProgress
} from "@chakra-ui/react";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import formatData from "../variables/formatData";

const Mockup = (props) => {

  const [activeNav, setActiveNav] = useState(1);
  // fazer 4 pedidos graficos para Hj
  const dataChart1 = chartExample1["data6"];
  const dataChart2 = chartExample1["data1"];
  const dataChart3 = chartExample1["data2"];
  const dataChart4 = chartExample1["data3"];
  const dataChart5 = chartExample1["data4"];
  // const [state, setState] = useState({startDate: null, endDate: null});

  const [chart1Data, setChart1Data] = useState(dataChart1);
  const [chart2Data, setChart2Data] = useState(dataChart2);
  const [chart3Data, setChart3Data] = useState(dataChart3);
  const [chart4Data, setChart4Data] = useState(dataChart4);
  const [chart5Data, setChart5Data] = useState(dataChart5);

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingChart1, setLoadingChart1] = useState(false);
  const [loadingChart2, setLoadingChart2] = useState(false);
  const [loadingChart3, setLoadingChart3] = useState(false);

  const [timeFrameChart1, setTimeFrameChart1] = useState("This Year");
  const [timeFrameChart2, setTimeFrameChart2] = useState("Today");
  const [timeFrameChart4, setTimeFrameChart4] = useState("Today");
  const [timeFrameChart5, setTimeFrameChart5] = useState("This Year");

  const [state, changeState] = useState({startDate: null, endDate: null});
  async function setState(date, val){
    var res = {startDate: state.startDate, endDate: state.endDate};
    val = val.toISOString().split('T')[0];
    if (date === 'start') {
      res.startDate = val;
    } else {
      res.endDate = val;
    }
    changeState(res);
  }

  function getDatesForFormat(timeFrame){
    var dateRange = {
      dataInicio: null,
      dataFim: null
    };

    if (timeFrame === "Today") {
      dateRange.dataInicio = moment().format('YYYY-MM-DD');
      dateRange.dataFim = moment().add(1, 'days').format('YYYY-MM-DD');
    } 

    if (timeFrame === "This Month"){
      dateRange.dataInicio = moment().subtract(1, 'month').format('YYYY-MM-DD');
      dateRange.dataFim = moment().add(1, 'days').format('YYYY-MM-DD');
    }

    if (timeFrame === "This Year"){
      dateRange.dataInicio = moment().startOf('year').format('YYYY-MM-DD');
      dateRange.dataFim = moment().endOf('year').format('YYYY-MM-DD');
    }

    if (timeFrame === "Custom") {
      dateRange.dataInicio = state.startDate;
      dateRange.dataFim = state.endDate;
    } 
    return dateRange;
  }

  const [cpes, setCPEs] = useState([]);
  const [clients, setClients] = useState([]);

  const [cpeInfo, setCpeInfo] = useState([]);

  async function updateGraphs(){
    setTimeFrameChart1("Custom");
    setTimeFrameChart2("Custom");
    setTimeFrameChart4("Custom");
  }

  useEffect(() => { 
    setLoadingPage(true);
    setLoadingPage(false);
  }, []);


  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, dashboard, graphNumb, timeFrameClicked) => {
    e.preventDefault();
    // setActiveNav(dashboard);
    if (graphNumb === 1) {
      setTimeFrameChart1(timeFrameClicked);
      return 1;
    }
    if (graphNumb === 2) {
      setTimeFrameChart2(timeFrameClicked);
      return 1;
    }
    if (graphNumb === 4) {
      setTimeFrameChart4(timeFrameClicked);
      return 1;
    }
    if (graphNumb === 5) {
      setTimeFrameChart5(timeFrameClicked);
      return 1;
    }
    
  };

  useEffect(() => {
  
    const finalDataChart = {data: {labels: [
        "2023-01-15 1h",
        "2023-01-15 2h",
        "2023-01-15 3h",
        "2023-01-15 4h",
        "2023-01-15 5h",
        "2023-01-15 6h",
        "2023-01-15 7h",
        "2023-01-15 8h",
        "2023-01-15 9h",
        "2023-01-15 10h",
        "2023-01-15 11h",
        "2023-01-15 12h",
        "2023-01-15 13h",
        "2023-01-15 14h",
        "2023-01-15 15h",
        "2023-01-15 16h",
        "2023-01-15 17h",
        "2023-01-15 18h",
        "2023-01-15 19h",
        "2023-01-15 20h",
        "2023-01-15 21h",
        "2023-01-15 22h",
        "2023-01-15 23h",
        "2023-01-15 24h",
        "2023-01-16 1h",
        "2023-01-16 2h",
        "2023-01-16 3h",
        "2023-01-16 4h",
        "2023-01-16 5h",
        "2023-01-16 6h",
        "2023-01-16 7h",
        "2023-01-16 8h",
        "2023-01-16 9h",
        "2023-01-16 10h",
        "2023-01-16 11h",
        "2023-01-16 12h",
        "2023-01-16 13h",
        "2023-01-16 14h",
        "2023-01-16 15h",
        "2023-01-16 16h",
        "2023-01-16 17h",
        "2023-01-16 18h",
        "2023-01-16 19h",
        "2023-01-16 20h",
        "2023-01-16 21h",
        "2023-01-16 22h",
        "2023-01-16 23h",
        "2023-01-16 24h",
        "2023-01-17 1h",
        "2023-01-17 2h",
        "2023-01-17 3h",
        "2023-01-17 4h",
        "2023-01-17 5h",
        "2023-01-17 6h",
        "2023-01-17 7h",
        "2023-01-17 8h",
        "2023-01-17 9h",
        "2023-01-17 10h",
        "2023-01-17 11h",
        "2023-01-17 12h",
        "2023-01-17 13h",
        "2023-01-17 14h",
        "2023-01-17 15h",
        "2023-01-17 16h",
        "2023-01-17 17h",
        "2023-01-17 18h",
        "2023-01-17 19h",
        "2023-01-17 20h",
        "2023-01-17 21h",
        "2023-01-17 22h",
        "2023-01-17 23h",
        "2023-01-17 24h",
        "2023-01-18 1h",
        "2023-01-18 2h",
        "2023-01-18 3h",
        "2023-01-18 4h",
        "2023-01-18 5h",
        "2023-01-18 6h",
        "2023-01-18 7h",
        "2023-01-18 8h",
        "2023-01-18 9h",
        "2023-01-18 10h",
        "2023-01-18 11h",
        "2023-01-18 12h",
        "2023-01-18 13h",
        "2023-01-18 14h",
        "2023-01-18 15h",
        "2023-01-18 16h",
        "2023-01-18 17h",
        "2023-01-18 18h",
        "2023-01-18 19h",
        "2023-01-18 20h",
        "2023-01-18 21h",
        "2023-01-18 22h",
        "2023-01-18 23h",
        "2023-01-18 24h",
        "2023-01-19 1h",
        "2023-01-19 2h",
        "2023-01-19 3h",
        "2023-01-19 4h",
        "2023-01-19 5h",
        "2023-01-19 6h",
        "2023-01-19 7h",
        "2023-01-19 8h",
        "2023-01-19 9h",
        "2023-01-19 10h",
        "2023-01-19 11h",
        "2023-01-19 12h",
        "2023-01-19 13h",
        "2023-01-19 14h",
        "2023-01-19 15h",
        "2023-01-19 16h",
        "2023-01-19 17h",
        "2023-01-19 18h",
        "2023-01-19 19h",
        "2023-01-19 20h",
        "2023-01-19 21h",
        "2023-01-19 22h",
        "2023-01-19 23h",
        "2023-01-19 24h",
        "2023-01-20 1h",
        "2023-01-20 2h",
        "2023-01-20 3h",
        "2023-01-20 4h",
        "2023-01-20 5h",
        "2023-01-20 6h",
        "2023-01-20 7h",
        "2023-01-20 8h",
        "2023-01-20 9h",
        "2023-01-20 10h",
        "2023-01-20 11h",
        "2023-01-20 12h",
        "2023-01-20 13h",
        "2023-01-20 14h",
        "2023-01-20 15h",
        "2023-01-20 16h",
        "2023-01-20 17h",
        "2023-01-20 18h",
        "2023-01-20 19h",
        "2023-01-20 20h",
        "2023-01-20 21h",
        "2023-01-20 22h",
        "2023-01-20 23h",
        "2023-01-20 24h",
        "2023-01-21 1h",
        "2023-01-21 2h",
        "2023-01-21 3h",
        "2023-01-21 4h",
        "2023-01-21 5h",
        "2023-01-21 6h",
        "2023-01-21 7h",
        "2023-01-21 8h",
        "2023-01-21 9h",
        "2023-01-21 10h",
        "2023-01-21 11h",
        "2023-01-21 12h",
        "2023-01-21 13h",
        "2023-01-21 14h",
        "2023-01-21 15h",
        "2023-01-21 16h",
        "2023-01-21 17h",
        "2023-01-21 18h",
        "2023-01-21 19h",
        "2023-01-21 20h",
        "2023-01-21 21h",
        "2023-01-21 22h",
        "2023-01-21 23h",
        "2023-01-21 24h"], datasets: [
          [
            0.0265,
            0.02625,
            0.0255,
            0.023,
            0.02625,
            0.0265,
            0.026,
            0.0225,
            0.0265,
            0.02625,
            0.02475,
            0.02375,
            0.02625,
            0.0265,
            0.02475,
            0.02375,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0.138,
            0.2785,
            0.314,
            0.30025,
            0.241,
            0.07075,
            0.0305,
            0.0305,
            0.03225,
            0.03225,
            0.0325,
            0.0315,
            0.02925,
            0.0325,
            0.03275,
            0.03225,
            0.031,
            0.03125,
            0.03125,
            0.04725,
            0.1135,
            0.2905,
            0.348,
            0.277,
            0.2955,
            0.3225,
            0.33325,
            0.33925,
            0.309,
            0.2965,
            0.2785,
            0.03275,
            0.031,
            0.031,
            0.031,
            0.02925,
            0.029,
            0.03175,
            0.03175,
            0.0315,
            0.02775,
            0.03125,
            0.0375,
            0.24225,
            0.27375,
            0.3045,
            0.27575,
            0.256,
            0.285,
            0.3005,
            0.31775,
            0.32275,
            0.306,
            0.292,
            0.27175,
            0.15925,
            0.02975,
            0.02975,
            0.0295,
            0.02875,
            0.02675,
            0.02975,
            0.02975,
            0.028,
            0.0275,
            0.03,
            0.02825,
            0.1485,
            0.29725,
            0.289,
            0.318,
            0.2745,
            0.2875,
            0.29675,
            0.3045,
            0.3045,
            0.245,
            0.17375,
            0.027,
            0.0285,
            0.0295,
            0.0295,
            0.02975,
            0.0255,
            0.0295,
            0.02975,
            0.0295,
            0.026,
            0.02925,
            0.03075,
            0.032,
            0.1205,
            0.0685,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ],
          [
            0.0265,
            0.02625,
            0.0255,
            0.023,
            0.02625,
            0.0265,
            0.026,
            0.0225,
            0.0265,
            0.02625,
            0.02475,
            0.02375,
            0.02625,
            0.0265,
            0.02475,
            0.02375,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            0.138,
            0.2785,
            0.314,
            0.30025,
            0.241,
            0.07075,
            0.0305,
            0.0305,
            0.03225,
            0.03225,
            0.0325,
            0.0315,
            0.02925,
            0.0325,
            0.03275,
            0.03225,
            0.031,
            0.03125,
            0.03125,
            0.04725,
            0.1135,
            0.2905,
            0.348,
            0.277,
            0.2955,
            0.3225,
            0.33325,
            0.33925,
            0.309,
            0.2965,
            0.2785,
            0.03275,
            0.031,
            0.031,
            0.031,
            0.02925,
            0.029,
            0.03175,
            0.03175,
            0.0315,
            0.02775,
            0.03125,
            0.0375,
            0.24225,
            0.27375,
            0.3045,
            0.27575,
            0.256,
            0.285,
            0.3005,
            0.31775,
            0.32275,
            0.306,
            0.292,
            0.27175,
            0.15925,
            0.02975,
            0.02975,
            0.0295,
            0.02875,
            0.02675,
            0.02975,
            0.02975,
            0.028,
            0.0275,
            0.03,
            0.02825,
            0.1485,
            0.29725,
            0.289,
            0.318,
            0.2745,
            0.2875,
            0.29675,
            0.3045,
            0.3045,
            0.245,
            0.17375,
            0.027,
            0.0285,
            0.0295,
            0.0295,
            0.02975,
            0.0255,
            0.0295,
            0.02975,
            0.0295,
            0.026,
            0.02925,
            0.03075,
            0.032,
            0.1205,
            0.0685,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null
          ]]}, 
          seriesREN: [
            "Energia entrada",
            "Energia saída",
            "Diferença"
          ], 
          areasSemValor: [
            [
              {
                "name": "Sem valor",
                "xAxis": "2023-01-15 17h"
              },
              {
                "xAxis": "2023-01-16 15h"
              }
            ],
            [
              {
                "name": "Sem valor",
                "xAxis": "2023-01-20 13h"
              },
              {
                "xAxis": "2023-01-21 24h"
              }
            ]
          ], horasSemValor: [], maxYValue: 0.38280000000000003};
    const bar_ctx = document.getElementById('canvas');
    var myChart = echarts.init(bar_ctx); 
    var option;
        
    option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            },
            backgroundColor: 'rgba(255, 255, 255, 0.8)'
        },
        legend: {
            show: true,
            top: 10,
            left: 'center',
            data: finalDataChart.seriesREN
        },
        grid: { 
            right: '15%'
        },
        toolbox: {
            feature: {
            dataZoom: {
                yAxisIndex: 'none'
            },
            restore: {},
            saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            triggerEvent: true,
            data : finalDataChart.data.labels,
            name: "Hora",
            nameLocation: "middle",
            nameTextStyle: {
                align: "center",
                verticalAlign: "top",
                lineHeight: 28
            },
        },
        yAxis: {
            type: 'value',
            // boundaryGap: [0, '100%'],
            name: 'A+ (kWh)',
            nameLocation: 'middle',
            nameTextStyle: {
                borderDashOffset: 3,
                align: 'center',
                verticalAlign: 'bottom',
                lineHeight: 46
                },
            max: finalDataChart.maxYValue
        },
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 10
            },
            {
                start: 0,
                end: 10
            }
        ],
        series: [
          {
            name: finalDataChart.seriesREN[2],
            type: 'line',
            data: finalDataChart.data.datasets[2],
            smooth: true,
            lineStyle: {
                normal: { opacity: 0.5, color: 'blue' }
            },
            itemStyle: {
              color: 'blue'
            },
            markArea: {
              itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                      offset: 0,
                      color: 'white'
                  },
                  {
                      offset: 1,
                      color: 'rgba(255,0,0,0.4)'
                  }
                  ])
              }, 
              data: finalDataChart.areasSemValor
            },
            markLine: {
              itemStyle: {
                  color: 'rgba(255, 173, 177, 0.4)'
              },
              data: finalDataChart.horasSemValor
            }
          },
          {
              name: finalDataChart.seriesREN[0],
              type: 'line',
              data: finalDataChart.data.datasets[0],
              smooth: true,
              lineStyle: {
                  normal: { opacity: 0.5, color: '#ffcc00' }
              },
              itemStyle: {
                color: '#ffcc00'
              },
              markArea: {
                  itemStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                          offset: 0,
                          color: 'white'
                      },
                      {
                          offset: 1,
                          color: 'rgba(255,0,0,0.4)'
                      }
                      ])
                  }, 
                  data: finalDataChart.areasSemValor
              },
              markLine: {
                  itemStyle: {
                      color: 'rgba(255, 173, 177, 0.4)'
                  },
                  data: finalDataChart.horasSemValor
              }
          },
          {
              name: finalDataChart.seriesREN[1],
              type: 'line',
              data: finalDataChart.data.datasets[1],
              smooth: true,
              lineStyle: {
                  normal: { opacity: 0.5, color: 'black' }
              },
              itemStyle: {
                color: 'black'
              },
              markArea: {
                  itemStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                          offset: 0,
                          color: 'white'
                      },
                      {
                          offset: 1,
                          color: 'rgba(255,0,0,0.4)'
                      }
                      ])
                  }, 
                  data: finalDataChart.areasSemValor
              },
              markLine: {
                  itemStyle: {
                      color: 'rgba(255, 173, 177, 0.4)'
                  },
                  data: finalDataChart.horasSemValor
              }
          }
        ]
    };
    option && myChart.setOption(option);
  }, []);
  

  if(!loadingPage){

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid style={{'backgroundColor': 'white'}}>

          <Row>
            <Col>
              <UncontrolledDropdown nav>
                <DropdownToggle caret className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block" nav>{cpeInfo.ParFiltrar}</DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow-right">
                
                  {clients.map(client => (
                      <DropdownItem key={client.parFiltrar}>
                        <NavItem>
                          <NavLink>
                            <span className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block">{client.parFiltrar}</span>
                          </NavLink>
                        </NavItem>
                      </DropdownItem>
                  ))}
                
                  <DropdownItem >
                    <NavItem>
                      <NavLink>
                        <span className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block">Total</span>
                      </NavLink>
                    </NavItem>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>

            <Col>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink className="nav-link-icon">
                    <h2>Consumidor/Prosumidor: Lista de Pontos Entrega</h2>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            
            
            {/* {cpeInfo.isConsumidor === "1" ? ( cpeInfo.isProdutor === "1" ? (
                <Col>
                  <Nav className="ml-auto" navbar>
                    <NavItem>
                      <NavLink className="nav-link-icon">
                        <h2>Prosumidor</h2>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                ) : (
                  <Col>
                    <Nav className="ml-auto" navbar>
                      <NavItem>
                        <NavLink className="nav-link-icon">
                          <h2>Consumidor</h2>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                  )) : (
                    <Col>
                      <Nav className="ml-auto" navbar>
                        <NavItem>
                          <NavLink className="nav-link-icon">
                            <h2>Consumidor: {cpeInfo.isConsumidor}</h2>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
              )} */}

            <Col>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink className="nav-link-icon">
                    <h2>Cliente Faturação: Nome Cliente de Faturação</h2>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          
          <Row>
            <Col>
              <div><h1>Dashboard</h1></div>
            </Col>
          </Row>

          <Row>
            <Col xs={2}>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Start Date"
                    }}
                    timeFormat={false}
                    dateFormat="DD-MM-YYYY"
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      if (
                        state.startDate &&
                        state.endDate &&
                        state.startDate._d + "" === currentDate._d + ""
                      ) {
                        classes += " start-date";
                      } else if (
                        state.startDate &&
                        state.endDate &&
                        new Date(state.startDate._d + "") <
                          new Date(currentDate._d + "") &&
                        new Date(state.endDate._d + "") >
                          new Date(currentDate._d + "")
                      ) {
                        classes += " middle-date";
                      } else if (
                        state.endDate &&
                        state.endDate._d + "" === currentDate._d + ""
                      ) {
                        classes += " end-date";
                      }
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                    onChange={e => setState('start', e)}
                    closeOnSelect={true}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs={2}>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "End Date"
                    }}
                    timeFormat={false}
                    dateFormat="DD-MM-YYYY"
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      if (
                        state.startDate &&
                        state.endDate &&
                        state.startDate._d + "" === currentDate._d + ""
                      ) {
                        classes += " start-date";
                      } else if (
                        state.startDate &&
                        state.endDate &&
                        new Date(state.startDate._d + "") <
                          new Date(currentDate._d + "") &&
                        new Date(state.endDate._d + "") >
                          new Date(currentDate._d + "")
                      ) {
                        classes += " middle-date";
                      } else if (
                        state.endDate &&
                        state.endDate._d + "" === currentDate._d + ""
                      ) {
                        classes += " end-date";
                      }
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                    onChange={e => setState('end', e)}
                    closeOnSelect={true}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs={2}>
              <Button variant="primary" type="submit" onClick={updateGraphs}>
                <i className="fas fa-search"></i>
              </Button>
            </Col>
          </Row>

          <Row>
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      {/* <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6> */}
                      <h2 className="mb-0">Production & Consumption</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  {/* {loadingChart1 ? (
                    <Center h="calc(40vh)">
                      <CircularProgress isIndeterminate color="orange.300" />
                    </Center>
                  ) : ( */}
                    
                    <div className="chart" id="canvas">
                      {/* <div id="canvas"></div> */}
                    </div>

                  {/* )} */}
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      {/* <h6 className="text-uppercase text-light ls-1 mb-1">
                        Overview
                      </h6> */}
                      <h2 className="mb-0">Estimated vs Real Production</h2>
                    </div>
                    <div className="col-right">
                      <Nav className="align-items-center d-none d-md-flex" navbar>
                        <UncontrolledDropdown nav>
                          <DropdownToggle caret className="pr-0" nav>{timeFrameChart4}</DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow">
                            <DropdownItem>
                              <NavItem>
                                <NavLink
                                  className={classnames("py-2 px-3", {
                                    active: activeNav === 1
                                  })}
                                  data-toggle="tab"
                                  href="#pablo"
                                  onClick={(e) => toggleNavs(e, 1, 4, 'Today')}>
                                  <span className="d-none d-md-block">Today</span>
                                  <span className="d-md-none">H</span>
                                </NavLink>
                              </NavItem>
                            </DropdownItem>
                            <DropdownItem >
                              <NavItem>
                                <NavLink
                                  className={classnames("py-2 px-3", {
                                    active: activeNav === 2
                                  })}
                                  href="#pablo"
                                  onClick={(e) => toggleNavs(e, 2, 4, 'This Month')}>
                                  <span className="d-none d-md-block">This Month</span>
                                  <span className="d-md-none">D</span>
                                </NavLink>
                              </NavItem>
                            </DropdownItem>
                            <DropdownItem>
                              <NavItem>
                                <NavLink
                                  className={classnames("py-2 px-3", {
                                    active: activeNav === 3
                                  })}
                                  href="#pablo"
                                  onClick={(e) => toggleNavs(e, 3, 4, 'This Year')}>
                                  <span className="d-none d-md-block">This Year</span>
                                  <span className="d-md-none">Y</span>
                                </NavLink>
                              </NavItem>
                            </DropdownItem>
                            <DropdownItem disabled>
                              <NavItem>
                                <NavLink
                                  className={classnames("py-2 px-3", {
                                    active: activeNav === 3
                                  })}
                                  href="#pablo"
                                  onClick={(e) => toggleNavs(e, 4, 4, 'Custom')}>
                                  <span className="d-none d-md-block">Custom</span>
                                  <span className="d-md-none">M</span>
                                </NavLink>
                              </NavItem>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Line
                      data={chart4Data}
                      options={chartExample1.options}
                      getDatasetAtEvent={(e) => console.log(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <hr></hr>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="4">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      {/* <h6 className="text-uppercase text-light ls-1 mb-1">
                        Overview
                      </h6> */}
                      <h2 className="mb-0">Production Forecast</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Line
                      data={chart3Data}
                      options={chartExample1.options}
                      getDatasetAtEvent={(e) => console.log(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            
            <Col className="mb-5 mb-xl-0" xl="4" >
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      {/* <h6 className="text-uppercase text-light ls-1 mb-1">
                        Overview
                      </h6> */}
                      <h2 className="mb-0">Savings</h2>
                    </div>
                    <div className="col-right">
                      <Nav className="align-items-center d-none d-md-flex" navbar>
                        <UncontrolledDropdown nav>
                          <DropdownToggle caret className="pr-0" nav>{timeFrameChart5}</DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow">
                            <DropdownItem >
                              <NavItem>
                                <NavLink
                                  className={classnames("py-2 px-3", {
                                    active: activeNav === 2
                                  })}
                                  href="#pablo"
                                  onClick={(e) => toggleNavs(e, 2, 5, 'This Month')}>
                                  <span className="d-none d-md-block">This Month</span>
                                  <span className="d-md-none">D</span>
                                </NavLink>
                              </NavItem>
                            </DropdownItem>
                            <DropdownItem>
                              <NavItem>
                                <NavLink
                                  className={classnames("py-2 px-3", {
                                    active: activeNav === 3
                                  })}
                                  href="#pablo"
                                  onClick={(e) => toggleNavs(e, 3, 5, 'This Year')}>
                                  <span className="d-none d-md-block">This Year</span>
                                  <span className="d-md-none">Y</span>
                                </NavLink>
                              </NavItem>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Line
                      data={chart5Data}
                      options={chartExample1.options}
                      getDatasetAtEvent={(e) => console.log(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col className="mb-5 mb-xl-0" xl="4">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Alerts</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        size="sm">See all</Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <div style={{borderLeft: "2px solid #ffcc00"}}>
                          <span style={{paddingLeft: '5px'}}>Consumption surpassed Production</span>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div style={{borderLeft: "2px solid #ffcc00"}}>
                          <span style={{paddingLeft: '5px'}}>Estimated Production very unusual compared to Actual Production</span>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  else{
    return (
      <>
        <Header />
        <Container className="mt--7" fluid style={{'backgroundColor': 'white'}}>
          <Center h="calc(100vh)">
            <CircularProgress isIndeterminate color="orange.300" />
          </Center>
        </Container>
      </>
    );
  }
};

export default Mockup;
